import React from 'react';
import Img from 'gatsby-image';

export default ({ title, date, thumbnailFluid, path, excerpt }) => (
    <div className="ie-post-preview">
        <h2 className="ie-post-title">
            <a href={path}>{title}</a>
        </h2>
        <div className="ie-post-date">{date}</div>
        {thumbnailFluid && (
            <div className="ie-post-thumbnail">
                <Img fluid={thumbnailFluid} />
            </div>
        )}
        {excerpt && (
            <p
                className="ie-post-content"
                dangerouslySetInnerHTML={{ __html: excerpt }}
            />
        )}
        <a href={path} className="ie-link">
            Продолжение...
        </a>
    </div>
);
