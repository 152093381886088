import React from 'react';
import Layout from './Layout';
import SEO from './Seo';
import PostPreview from './PostPreview';
import { graphql } from 'gatsby';

export default ({ data, pageContext }) => {
    const posts = data.allMdx.edges.map(edge => ({
        excerpt: edge.node.excerpt,
        date: edge.node.frontmatter.date,
        title: edge.node.frontmatter.title,
        path: edge.node.frontmatter.path,
        thumbnailFluid:
            edge.node.frontmatter.thumbnail && edge.node.frontmatter.thumbnail.childImageSharp
                ? edge.node.frontmatter.thumbnail.childImageSharp.fluid
                : null,
    }));

    const renderPagination = () => {
        const { currentPage, numPages } = pageContext;
        const isFirst = currentPage === 1;
        const isLast = currentPage === numPages;
        const prevPage = currentPage === 2 ? '/' : `/${currentPage - 1}`;
        const nextPage = `/${currentPage + 1}`;

        return (
            <div className="ie-page-navigation">
                {!isFirst ? (
                    <a href={prevPage} rel="prev" className="ie-link">
                        ← Вперёд
                    </a>
                ) : (
                    <span />
                )}
                {!isLast ? (
                    <a href={nextPage} rel="next" className="ie-link">
                        Назад →
                    </a>
                ) : (
                    <span />
                )}
            </div>
        );
    };

    return (
        <Layout>
            <SEO title="Игорь Еремеев" />
            <div>
                {posts.map(post => (
                    <PostPreview key={post.path} {...post} />
                ))}
            </div>
            {renderPagination()}
        </Layout>
    );
};

export const pageQuery = graphql`
    query blogPageQuery($skip: Int!, $limit: Int!) {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { public: { eq: true } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    excerpt(pruneLength: 240)
                    frontmatter {
                        date(formatString: "D MMMM YYYY года", locale: "ru")
                        title
                        path
                        thumbnail {
                            childImageSharp {
                                fluid(quality: 60) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
